<template>
  <ion-page>
    <ion-content fullscreen>
      <!-- <ion-item>
        <ion-label>
          <h1>{{$t('labelNotebookInfo')}}: {{ qrcodeInfo.info.sign }}</h1>
          <p>{{ qrcodeInfo.info.ver }}</p>
        </ion-label>
      </ion-item> -->
      <ion-item>
        <ion-label>
          <h1>{{$t('labelChipset')}}:</h1>
          <p>{{$t('labelMainboard')}}: {{ qrcodeInfo.inspection_data.chipset.mainboard }}</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <h1>{{$t('labelCpu')}}:</h1>
          <p v-for="(item, index) in qrcodeInfo.inspection_data.cpu" :key="index">{{ item.specification }}</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <h1>{{$t('labelAdapter')}}:</h1>
          <p v-for="(item, index) in qrcodeInfo.inspection_data.display" :key="index">{{ item.adapter }}</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <h1>{{$t('labelDmi')}}:</h1>
          <p>{{$t('labelModel')}}: {{ qrcodeInfo.inspection_data.dmi.model }}</p>
          <p>{{$t('labelProduct')}}: {{ qrcodeInfo.inspection_data.dmi.product }}</p>
          <p>{{$t('labelSerial')}}: {{ qrcodeInfo.inspection_data.dmi.serial }}</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <h1>{{$t('labelMemory')}}:</h1>
          <p>{{ qrcodeInfo.inspection_data.chipset.memorysize }}</p>
          <!-- <p v-for="(item, index) in qrcodeInfo.inspection_data.memory" :key="index">{{ item.size }}</p> -->
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <h1>{{$t('labelMonitor')}}:</h1>
          <p v-for="(item, index) in qrcodeInfo.inspection_data.monitor" :key="index">
            {{$t('labelManufacturingdate')}}: {{ item.manufacturingdate }}<br/>
            {{$t('labelModel')}}: {{ item.model }}<br/>
            {{$t('labelSerial')}}: {{ item.serial }}<br/>
            {{$t('labelSize')}}: {{ item.size }}<br/>
          </p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <h1>storage:</h1>
          <p v-for="(item, index) in qrcodeInfo.inspection_data.storage" :key="index">
            {{$t('labelManufacturingdate')}}: {{ item.capacity }}<br/>
            {{$t('labelModel')}}: {{ item.type }}<br/>
          </p>
        </ion-label>
      </ion-item>
      <ion-button v-if="!serialCheck" color="primary" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage,
  IonContent,
  IonButton,
  IonItem,
  IonLabel,
  useIonRouter
} from '@ionic/vue'
import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { updateData, listData, getData } from '@/api/board/common'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'inspection-info',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonItem,
    IonLabel
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const router = useIonRouter()
    const route = useRoute()
    const store = useStore()
    const qrcodeInfo = store.state.Qrcode.qrcodeInfo
    const allCategory = ref([])
    let payload = ref({
      // order_num: this.$moment().format('YYYYMMDD'),
      json_data: JSON.stringify(store.state.Qrcode.qrcodeInfo),
      total_price: '',
      cpu: '', 
      ram: '',
      vga: '',
      lcd_size: '',
      cpu_num: '',
      ram_num: '',
      vga_num: '',
      lcd_size_num: ''
    })
    const serialCheck = ref(false);

    onMounted(async () => {
      // console.log(JSON.stringify(store.state.Qrcode.qrcodeInfo))
      await getSerialCheck()
      await getAllCategory()
    })

    const handleNext = async () => {
      await checkCategory()
      await updateOrder()

      router.push({
        name: 'survey',
        params: {
          num: route.params.num
        }
      })
    }

    const updateOrder = async () => {
      const apiUrl = `/api/notebook/order/`
      payload.value.num = Number(route.params.num)
      await updateData(apiUrl, payload.value)
    }

    const getAllCategory = async () => {
      const apiUrl = '/api/notebook/allcategory'
      allCategory.value = await listData(apiUrl)
    }

    const getSerialCheck = async () => {
      const apiUrl = '/api/notebook/serialcheck'
      const cnt = await getData(apiUrl, store.state.Qrcode.qrcodeInfo.inspection_data.dmi.serial);
      if (cnt > 0) {
        serialCheck.value = true;
        alert(t('messageSerialCheck'));
      }
    }

    const checkCategory = () => {
      // check Category
      let totalPrice = 0
      allCategory.value.forEach(o => {
        // check CPU
        if (o.cate_name === store.state.Qrcode.qrcodeInfo.inspection_data.cpu[0].specification) {
          payload.value.cpu_num = o.cate_num
          payload.value.cpu = Number(o.price)
          totalPrice += Number(o.price)
        } 

        // check RAM
        if (o.cate_name === store.state.Qrcode.qrcodeInfo.inspection_data.chipset.memorysize) {
          payload.value.ram_num = o.cate_num
          payload.value.ram = Number(payload.value.ram) + Number(o.price)
          totalPrice += Number(o.price)
        }
        /* store.state.Qrcode.qrcodeInfo.inspection_data.memory.filter(e => {
          if (o.cate_name === e.size) {
            payload.value.ram_num = o.cate_num
            payload.value.ram = Number(payload.value.ram) + Number(o.price)
            totalPrice += Number(o.price)
          }
        }) */

        // check DISPLAY
        store.state.Qrcode.qrcodeInfo.inspection_data.display.filter(e => {
          if (o.cate_name === e.adapter) {
            payload.value.vga_num = o.cate_num
            payload.value.vga = Number(payload.value.vga) + Number(o.price)
            totalPrice += Number(o.price)
          }
        })

        // check LCD
        store.state.Qrcode.qrcodeInfo.inspection_data.monitor.filter(e => {
          if (o.cate_name === e.size) {
            payload.value.lcd_size_num = o.cate_num
            payload.value.lcd_size = Number(payload.value.lcd_size) + Number(o.price)
            totalPrice += Number(o.price)
          }
        })
      })
      payload.value.total_price = totalPrice
    }

    return {
      payload,
      qrcodeInfo,
      allCategory,
      handleNext,
      updateOrder,
      getAllCategory,
      checkCategory,
      serialCheck
    }
  }
})
</script>

<style>

</style>